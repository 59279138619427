import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { Flex, Link as RebassLink, Text } from 'rebass/styled-components'

import Section from './section'
import Container from './container'
import BackgroundImage from './background-image'
import getUri from '../utils/getUri'

const NextStep = ({
  title,
  link,
  label,
  backgroundImage,
  titleId,
  titleDefaultMessage,
  labelId,
  labelDefaultMessage,
}) => {
  if (!link) {
    return null
  }

  return (
    <BackgroundImage
      beforeOpacity="0.6"
      fluid={backgroundImage.childImageSharp.fluid}
    >
      <Section sx={{ py: [4, 5, 6], background: 'transparent' }}>
        <Container>
          <Flex
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Text as="p" variant="nextStepTitle">
              {titleId && (
                <FormattedMessage
                  id={titleId}
                  titleDefaultMessage={titleDefaultMessage}
                />
              )}
              {!titleId && title}
            </Text>

            <Link to={getUri(link)}>
              <RebassLink
                as="span"
                variant="primaryLarge"
                sx={{ display: 'inline-block' }}
              >
                {labelId && (
                  <FormattedMessage
                    id={labelId}
                    labelDefaultMessage={labelDefaultMessage}
                  />
                )}
                {!labelId && label}
              </RebassLink>
            </Link>
          </Flex>
        </Container>
      </Section>
    </BackgroundImage>
  )
}

NextStep.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  backgroundImage: PropTypes.object,
  titleId: PropTypes.string,
  titleDefaultMessage: PropTypes.string,
  labelId: PropTypes.string,
  labelDefaultMessage: PropTypes.string,
}

NextStep.defaultProps = {
  titleId: null,
  titleDefaultMessage: null,
  labelId: null,
  labelDefaultMessage: null,
}

export default NextStep
