import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Flex } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionWithObjects } from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import NextStep from '../components/next-step'
import getTranslations from '../utils/getTranslations'

const PodporaPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const {
    page,
    heroBackgroundImage,
    nextStepBackground,
    dotaceOpice1,
    dotaceOpice2,
  } = data
  const { title, acf, polylang_translations: translations } = page
  const {
    text_kam_dal: textKamDal,
    popisek_tlacitka_kam_dal: popisekTlacitkaKamDal,
    odkaz_kam_dal: odkazKamDal,
  } = acf

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(locale, translations)}
    >
      <SEO title={title} />

      <Hero
        title={title}
        backgroundImage={heroBackgroundImage}
        backgroundPosition="50% 30%"
      />

      <SectionWithObjects>
        <Container>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <Img
              fluid={dotaceOpice1.childImageSharp.fluid}
              alt=""
              style={{
                width: '100%',
                borderRadius: '0.5rem',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              }}
            />

            <Img
              fluid={dotaceOpice2.childImageSharp.fluid}
              alt=""
              style={{
                width: '100%',
                borderRadius: '0.5rem',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              }}
            />
          </Flex>
        </Container>
      </SectionWithObjects>

      <NextStep
        title={textKamDal || ''}
        link={odkazKamDal}
        label={popisekTlacitkaKamDal}
        backgroundImage={nextStepBackground}
      />
    </Layout>
  )
}

PodporaPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default PodporaPage

export const pageQuery = graphql`
  query PodporaPageQuery($key: String!) {
    page: wordpressPage(slug: { eq: $key }) {
      ...PageData
      ...PageTranslations
      acf {
        ...NextStep
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "tym-opice.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "pivo-v-ruce.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    euNotice: file(relativePath: { eq: "eu-notice.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 586) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dotaceOpice1: file(relativePath: { eq: "dotace-opice-1.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 586) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dotaceOpice2: file(relativePath: { eq: "dotace-opice-2.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 586) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
