import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'gatsby'
// import { FormattedMessage } from 'react-intl'
import {
  Box, Flex, Heading, Text,
} from 'rebass/styled-components'

import Section from './section'
import Container from './container'
import BackgroundImage from './background-image'
import TiltLabel from './tilt-label'

const Hero = ({
  title, text, html, backgroundImage, backgroundPosition, etiquette, children,
}) => {
  return (
    <BackgroundImage
      fluid={backgroundImage.childImageSharp.fluid}
      style={{
        backgroundPosition: backgroundPosition || '50% 50%',
        backgroundColor: '#333',
        backgroundSize: 'cover',
      }}
    >
      <Section
        sx={{
          background: [
            'linear-gradient(0, rgba(49, 40, 2, 0) 0%, rgba(49, 40, 2, 0.75) 50%)',
            'linear-gradient(180deg, rgba(49, 40, 2, 0) 0%, rgba(49, 40, 2, 0.75) 50%)',
          ],
        }}
      >
        <Container>
          <Flex
            flexDirection={['column', 'row']}
            alignItems={['center', 'flex-start']}
            justifyContent={['flex-start', 'space-between']}
          >
            <Box sx={{ mb: [4, 0], pt: [0, 4], flexBasis: ['100%', '55%'] }}>
              <Heading variant="carouselTitle" as="h1">
                {title}
              </Heading>

              {text && <Text variant="slideText">{text}</Text>}

              {html && (
                <Text
                  dangerouslySetInnerHTML={{ __html: html }}
                  variant="slideText"
                />
              )}
            </Box>

            {etiquette && (
              <Box sx={{ flexBasis: ['100%', '40%'] }}>
                <TiltLabel
                  background={etiquette.background}
                  top={etiquette.top}
                  bottom={etiquette.bottom}
                  size="24rem"
                />
              </Box>
            )}

            {children && <Box sx={{ flexBasis: '100%' }}>{children}</Box>}
          </Flex>
        </Container>
      </Section>
    </BackgroundImage>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  html: PropTypes.string,
  backgroundImage: PropTypes.object.isRequired,
  backgroundPosition: PropTypes.string,
  etiquette: PropTypes.object,
  children: PropTypes.node,
}

Hero.defaultProps = {
  backgroundPosition: '50% 50%',
  etiquette: null,
  children: null,
}

export default Hero
